import React, { useEffect, useState, createContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
	setInLocalStorage,
	localStorageKeys,
	getFromLocalStorage,
} from '../../../core/utility/localStorage';

import { useCheckCareProSessionQuery } from '../../api/queries/useCheckCareProSessionQuery';

import { authRoutes } from '../../../auth/routes/constants';
import { coreRoutes } from '../../routes/constants';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isRegistryApproved, setIsRegistryApproved] = useState(false);
	const [authCarePro, setAuthCarePro] = useState(null);
	const [statusUpdate, setStatusUpdate] = useState('Pending Registry Approval');
	const [hasSetProfessionalStatement, setHasSetProfessionalStatement] = useState(false);
	const [hasCompletedStripeOnboarding, setHasCompletedStripeOnboarding] = useState(false);
	const [isStripeVerified, setIsStripeVerified] = useState(false);
	const [hasCompletedPlatformOnboarding, setHasCompletedPlatformOnboarding] = useState(false);
	const [pendingStripeDocumentRequirements, setPendingStripeDocumentRequirements] =
		useState(false);

	const [completedDocumentApproval, setCompletedDocumentApproval] = useState(false);

	const history = useHistory();

	const {
		data,
		isLoading: appIsLoading,
		refetch: refreshAuthCarePro,
	} = useCheckCareProSessionQuery();

	console.log('data', data);

	const [testCounter, setTestCounter] = useState(0);
	const [otherCounter, setOtherCounter] = useState(0);
	console.log('testCounter', testCounter);
	console.log('otherCounter', otherCounter);
	useEffect(() => {
		setTestCounter((prev) => prev + 1);
		if (authCarePro) {
			if (
				authCarePro.careProRegistryStatus &&
				authCarePro.careProRegistryStatus.registry_approval
			) {
				setIsRegistryApproved(true);
			}
			if (authCarePro.professional_statement || authCarePro.professional_statement === '') {
				setHasSetProfessionalStatement(true);
			}

			if (authCarePro.stripeStatus.status === 'verified') {
				setIsStripeVerified(true);
			}

			if (
				authCarePro.stripeStatus.status !== 'unverified' &&
				authCarePro.stripeStatus.status !== ''
			) {
				setHasCompletedStripeOnboarding(true);
			}

			if (authCarePro.careProRegistryStatus.platform_onboard) {
				setHasCompletedPlatformOnboarding(true);
			}

			if (authCarePro.stripeStatus.status)
				if (
					authCarePro.stripeStatus.stripe_data.currently_due.filter(
						(documentRequirement) => documentRequirement.endsWith('.document')
					).length > 0
				) {
					setPendingStripeDocumentRequirements(true);
				}
			if (authCarePro.careProRegistryStatus.documents_approval) {
				setCompletedDocumentApproval(true);
			}
		}
	}, [isAuthenticated, authCarePro]);

	useEffect(() => {
		if (!appIsLoading) {
			setOtherCounter((prev) => prev + 1);

			console.log('appIsLoading', appIsLoading);
			const token = getFromLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN);

			if (token && data) {
				setAuthCarePro(data);
				setIsAuthenticated(true);
			} else {
				setAuthCarePro(null);
				setIsAuthenticated(false);
				setInLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN);
			}

			/* 
				- If the CarePro has Created a account but not completed 
				- Registry Onboard (Documents)
				- Stripe Onboard

				* the platform should link them to the respective pages if not already there
			*/

			// 1) determine where in the onboarding phase they are in
			/* 
				this is data that can be determiend from the data var returned from the checkProviderSession query

				* we dont' need to determine which exact route they should be on since the subroutes determine that when they land but the general top level route /documents or /auth

				* if `careProRegistryStatus.platform_onboard` is false then we should take them to the /auth route

			*/
			if (authCarePro && data) {
				/* 
					we are only manually routing them if 
					- they have already created a account
					- they don't have a professional statement
					- they hav have not completed stripe onboarding
					- they have not uploaded all the required documents 
				*/
				// this will only get here if they have a base platform account

				// check if they have a professional statement
				if (data.professional_statement === null) {
					// route them to the professional statement page

					// safety check to ensure we're not already on the professional statement page
					if (history.location.pathname !== authRoutes.onboarding.professionalStatement)
						history.push(authRoutes.onboarding.professionalStatement);
				} else if (data.stripeStatus.status === 'unverified') {
					// check if they have completed the stripe onboarding
					// route them to the stripe onboarding page

					// safety check to ensure we're not already on the stripe onboarding page
					if (history.location.pathname !== authRoutes.onboarding.stripe.home)
						history.push(authRoutes.onboarding.stripe.home);
				} else if (!data.careProRegistryStatus.document_approval) {
					// safety check to ensure we're not already on the documents page
					if (history.location.pathname !== coreRoutes.documents)
						history.push(coreRoutes.documents);
				}
			}
		}
	}, [data, appIsLoading]);

	const loginCarePro = (token, carePro) => {
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN, token);
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_LEGACY_AUTH_TOKEN, token);
		setAuthCarePro(carePro);
		setIsAuthenticated(true);
	};

	const logoutCarePro = () => {
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN);
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_LEGACY_AUTH_TOKEN);
		setAuthCarePro(null);
		setIsAuthenticated(false);
	};

	const modifyAuthCarePro = (key, value) => {
		setAuthCarePro((prevAuthCarePro) => ({
			...prevAuthCarePro,
			[key]: value,
		}));
	};

	const changeRegistryApproval = (approval, status) => {
		setIsRegistryApproved(approval);
		if (status) setStatusUpdate(status);
	};

	const value = {
		isAuthenticated,
		isRegistryApproved,
		hasSetProfessionalStatement,
		hasCompletedStripeOnboarding,
		hasCompletedPlatformOnboarding,
		isStripeVerified,
		pendingStripeDocumentRequirements,
		authCarePro,
		statusUpdate,
		appIsLoading,
		loginCarePro,
		logoutCarePro,
		refreshAuthCarePro,
		changeRegistryApproval,
		modifyAuthCarePro,
		completedDocumentApproval,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
