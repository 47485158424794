import styled from 'styled-components';
import React from 'react';

import { colors } from '../../../../../corev2/utils';

export const StyledDocumentsPage = styled.div``;

export const StyledDocumentsContainer = styled.div`
	width: 100vw;
	background-color: #edf6fb;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding: 40px;

	@media (max-width: 768px) {
		padding: 10px;
	}

	@media (max-width: 480px) {
		padding: 5px;
	}
`;

export const StyledDocumentsWrapper = styled.div`
	width: 100%;
	max-width: 1000px;
	box-sizing: border-box;
	padding: 20px;
	background-color: white;
	border: 1px solid rgba(43, 105, 166, 0.3);
	color: #2b69a3;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	margin-bottom: 24px;

	@media (max-width: 768px) {
		padding: 15px;
		max-width: 100%;
	}

	@media (max-width: 480px) {
		padding: 10px;
	}
`;

export const StyledTitle = styled.h3`
	font-size: 18px;
	font-weight: 700;
	color: #2b69a3;
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-bottom: 15px;
	text-align: left;

	@media (max-width: 480px) {
		font-size: 16px;
	}
`;

export const StyledDocumentRequest = styled.div`
	background-color: white;
	text-align: left;

	display: grid;
	grid-template-columns: repeat(2, 1fr); /* 2 cards per row on larger screens */
	gap: 20px;
	//   padding: 20px;

	@media (max-width: 768px) {
		grid-template-columns: 1fr; /* 1 card per row on smaller screens */
	}
`;

export const StyledDocumentCard = styled.div`
	background: #f0f8fd;
	border: 1px solid #cbd4db;
	border-radius: 10px;
	padding: 15px;
	display: flex;
	flex-direction: column;

	border: 1px solid rgba(43, 105, 166, 0.3);
	position: relative;
`;

export const StyledDocumentTitle = styled.h3`
	margin: 0;
	font-size: 1.1em;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #2b69a3;
`;

export const StyledDocumentUploadArea = styled.label`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
	margin-top: 10px;
	margin-bottom: 20px;
	border: 4px dashed rgba(43, 105, 166, 0.3);

	background-color: #fff;
	border-radius: 8px;
	cursor: pointer;
	color: #1e5a8e;
	font-size: 16px;
	text-align: center;
`;

export const StyledUploadFilesText = styled.span``;

export const StyledFileInput = styled.input`
	display: none;
`;

export const StypedExpirationLabel = styled.label`
	margin-bottom: 5px;
	font-size: 14px;
	color: #6c7a89;
`;

export const StyledClearFileIcon = styled.span`
	cursor: pointer;
	font-size: 16px;
	color: ${colors.primary.primary};
	position: absolute;
	right: 10px;
	top: 15px;
	transform: translateY(-50%);
	&:hover {
		color: font-size: 20px;
	}
`;

export const StyledButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 2rem;
`;

const UploadStyledButton = styled.button`
	border-radius: 24px;
	padding: ${({ size }) => (size === 'small' ? '0.5em 1em' : '0.6rem 1em')};
	background: ${colors.primary.primary};
	border: 1px solid ${colors.primary.primary};
	letter-spacing: 0.5px;
	text-align: center;
	font-size: ${({ size }) => (size === 'small' ? '0.875em' : '1.1em')};
	line-height: 1.7rem;
	color: ${colors.white};
`;
export const UploadButton = (props) => {
	const { children, ...restProps } = props;

	return <UploadStyledButton {...restProps}>{children}</UploadStyledButton>;
};
