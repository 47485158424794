import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { FormContainer, Input, Label, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import FormWrapper from '../FormWrapper';
import InputContainer from '../InputContainer';

import personalStripeFormValidationSchema from './validation';

const PersonalStripeForm = (props) => {
	const { initialValues, presonalStripeInfoHandler, loading } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => presonalStripeInfoHandler(values),
		validationSchema: toFormikValidationSchema(personalStripeFormValidationSchema),
		enableReinitialize: true,
	});

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<Label>Month </Label>

					<Input
						name='month'
						type='text'
						onChange={formik.handleChange}
						placeholder='Month'
						value={formik.values.month}
					/>

					{formik.errors.month && <ErrorMessage> {formik.errors.month} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Label> Day </Label>

					<Input
						name='day'
						type='text'
						onChange={formik.handleChange}
						placeholder='Day'
						value={formik.values.day}
					/>

					{formik.errors.day && <ErrorMessage> {formik.errors.day} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Label> Year </Label>

					<Input
						name='year'
						type='text'
						onChange={formik.handleChange}
						placeholder='Year'
						value={formik.values.year}
					/>

					{formik.errors.year && <ErrorMessage> {formik.errors.year} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Label> SSN </Label>

					<Input
						name='ssn'
						type='text'
						onChange={formik.handleChange}
						placeholder='SSN'
						value={formik.values.ssn}
					/>

					{formik.errors.ssn && <ErrorMessage> {formik.errors.ssn} </ErrorMessage>}
				</InputContainer>

				{loading ? (
					<p>Loading...</p>
				) : (
					<PrimaryButton type='submit' size='small'>
						Next
					</PrimaryButton>
				)}
			</FormWrapper>
		</FormContainer>
	);
};

export default PersonalStripeForm;
