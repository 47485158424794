import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Loader from '../../../../../corev2/Loader';
import { PrimaryButton, DangerButton } from '../../../../../corev2/Buttons';
import EditDetailsForm from '../../components/EditDetailsForm';
import PayoutMethodForm from '../../components/PayoutMethodForm';
import PayoutMethodCard from '../../components/PayoutMethodCard';
import PayoutMethodBank from '../../components/PayoutMethodBank';

import {
	StyledContainer,
	StyledWelcomeSection,
	StyledWelcomeHeading,
	StyledWelcomeText,
	StyledContentWrapper,
	StyledAccountSection,
	StyledInnerAccountSection,
	StyledPayoutSection,
	StyledInnerPayoutSection,
	StyledSectionTitle,
	StyledButtonsContainer,
	StyledPayoutMethodsContainer,
	StyledPayoutInfoText,
} from './styles.js';

import { useFetchPayoutMethodsQuery } from '../../api/queries/useFetchPayoutMethods';
import { useUpdateCareProMutation } from '../../api/mutations/useUpdateCareProMutation';
import { useDeletePayoutMethodMutation } from '../../api/mutations/useDeletePayoutMethodMutation';
import { useAddExternalCardSourceMutation } from '../../api/mutations/useAddExternalCardSourceMutation';
import { useChangeDefaultPayoutSourceMutation } from '../../api/mutations/useChangeDefaultPayoutSourceMutation';

import Notification from '../../../../../client/modules/core/lib/notification';
import { notifications } from '../../../../../client/modules/core/constants';

import { useAuth } from '../../../core/hooks/useAuth';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const DashboardPage = () => {
	const [serverError, setServerError] = useState('');

	const { authCarePro, logoutCarePro } = useAuth();

	const {
		data: payoutMethods,
		isLoading: isLoadingPayoutMethods,
		refetch: refreshPayoutsData,
	} = useFetchPayoutMethodsQuery();

	const updateCarePro = useUpdateCareProMutation();
	const deletePayoutMethod = useDeletePayoutMethodMutation();
	const addExternalCardSource = useAddExternalCardSourceMutation();
	const changeDefaultPayoutSource = useChangeDefaultPayoutSourceMutation();

	const updateCareProHandler = async (values) => {
		try {
			await updateCarePro.mutateAsync({
				careProId: authCarePro.id,
				profileInfo: { email: values.email, phone_number: values.phone },
			});

			// Notification(notifications.dashboard.accountInfo.updated);
		} catch (error) {
			console.error('Updating care pro failed', error);
			set;
		}
	};

	const addPayoutMethodHandler = async (tokenId) => {
		try {
			await addExternalCardSource.mutateAsync({
				externalCardTokenId: tokenId,
			});

			// Notification(notifications.dashboard.payoutMethods.payoutMethodAdded);
			refreshPayoutsData();
		} catch (error) {
			console.error('Add Payout method handler failed.', error);
		}
	};

	const updateDefaultPayoutMethodHandler = async (sourceId) => {
		try {
			await changeDefaultPayoutSource.mutateAsync({
				externalCardTokenId: sourceId,
			});

			// Notification(notifications.dashboard.paymentMethods.defaultPaymentMethodChanged);
			refreshPayoutsData();
		} catch (error) {
			console.error('Updating default payment method failed', error);
		}
	};

	const deletePayoutMethodHandler = async (sourceId) => {
		try {
			await deletePayoutMethod.mutateAsync({
				externalCardTokenId: sourceId,
			});

			Notification(notifications.dashboard.paymentMethods.paymentMethodDeleted);
			refreshPayoutsData();
		} catch (error) {
			console.error('Deleting payment method failed', error);
		}
	};

	console.log(payoutMethods);
	const logoutHandler = () => {
		logoutCarePro();
		Notification(notifications.auth.logout);
	};

	if (isLoadingPayoutMethods) return <Loader />;

	return (
		<StyledContainer>
			<StyledWelcomeSection>
				<StyledWelcomeHeading>WELCOME BACK</StyledWelcomeHeading>
				<StyledWelcomeText>
					This is the Settings section for CarePros such as yourself
				</StyledWelcomeText>
			</StyledWelcomeSection>

			<StyledContentWrapper>
				<StyledAccountSection>
					<StyledSectionTitle>ACCOUNT</StyledSectionTitle>
					<StyledInnerAccountSection>
						<EditDetailsForm
							initialValues={{
								email: authCarePro.email,
								phone: authCarePro.phone_number,
							}}
							updateCareProHandler={updateCareProHandler}
							serverError={serverError}
						/>

						<StyledButtonsContainer>
							<PrimaryButton size='small'>Change Password</PrimaryButton>
							<PrimaryButton size='small'>Export Invoices</PrimaryButton>
							<DangerButton size='small' onClick={logoutHandler}>
								Log Out
							</DangerButton>
						</StyledButtonsContainer>
					</StyledInnerAccountSection>
				</StyledAccountSection>

				<StyledPayoutSection>
					<StyledSectionTitle>PAYOUT METHODS</StyledSectionTitle>
					<StyledInnerPayoutSection>
						<StyledPayoutInfoText>
							In order to provide our best service we must have a payout method on
							file. Please add your card information below.
						</StyledPayoutInfoText>

						<StyledPayoutMethodsContainer>
							{payoutMethods.payoutMethods.map((payoutMethod) => {
								const { bank, card } = payoutMethod;

								if (payoutMethod.type === 'card') {
									return (
										<PayoutMethodCard
											key={payoutMethod.id}
											id={payoutMethod.id}
											last4={card.last_4}
											expiryMonth={card.exp_month}
											expiryYear={card.exp_year}
											cardBrand={card.brand}
											isDefault={payoutMethod.default_method}
											updateDefaultPayoutMethodHandler={
												updateDefaultPayoutMethodHandler
											}
											deletePayoutMethodHandler={deletePayoutMethodHandler}
										/>
									);
								}

								if (payoutMethod.type === 'bank') {
									return (
										<PayoutMethodBank
											key={payoutMethod.id}
											id={payoutMethod.id}
											bankName={bank.bank_name}
											last4={bank.last_4}
											isDefault={payoutMethod.default_method}
											updateDefaultPayoutMethodHandler={
												updateDefaultPayoutMethodHandler
											}
											deletePayoutMethodHandler={deletePayoutMethodHandler}
										/>
									);
								}
							})}
						</StyledPayoutMethodsContainer>

						<Elements stripe={stripePromise}>
							<PayoutMethodForm addPayoutMethodHandler={addPayoutMethodHandler} />
						</Elements>
					</StyledInnerPayoutSection>
				</StyledPayoutSection>
			</StyledContentWrapper>
		</StyledContainer>
	);
};

export default DashboardPage;
