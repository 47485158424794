import React, { useState, useEffect } from 'react';

import { PrimaryButton } from '../../../../../corev2/Buttons';
import { Input } from '../../../../../corev2/Forms';
import DocumentsList from '../../components/DocumentsList';

import { useFetchDocumentRequirements } from '../../api/queries/useFetchDocumentRequirementQuery';
import { useFetchUploadedDocumentsQuery } from '../../api/queries/useFetchUploadedDocumentsQuery';
import { useCareProUploadDocumentMutation } from '../../api/mutations/useCareProUploadDocumentMutation';
import { useUploadStripeDocumentsMutation } from '../../api/mutations/useUploadStripeDocumentsMutation';

import uploadFilesToAWSS3 from '../../utils/uploadFilesToAWSS3';

import { useAuth } from '../../../core/hooks/useAuth';

import { stripeDocumentRequests } from '../../lib/stripeDocumentRequests';

import {
	StyledDocumentsPage,
	StyledDocumentsContainer,
	StyledDocumentsWrapper,
	StyledTitle,
	StyledDocumentRequest,
	StyledDocumentCard,
	StyledDocumentTitle,
	StyledDocumentUploadArea,
	StyledUploadFilesText,
	StyledFileInput,
	StypedExpirationLabel,
	StyledClearFileIcon,
	StyledButtonContainer,
} from './styles';

import UploadTile from '../../components/UploadTile';

/* 
	 The CarePro should be able ot always be able to upload new documents 
	 the lifestyle of a upload follows
	 1) select a file ot uplaod form agency request
	 2) upload the file
	 3) files shows up in pending approval
	 4) files are approved by the agency
	 5) files are moved from pending to approved

	 these files are seperated between two type of return types
	 - document request 
	 - uploaded documents

	 document request are easy to handle since they don't really move from stage to stage
	 uploaded documents are a bit more complex since they can move from stage to stage (pendign to approved)
	 - or even be rejected
*/
const DocumentsPage = () => {
	const [filesToUploadAgency, setFilesToUploadAgency] = useState({});
	const [filesToUploadStripe, setFilesToUploadStripe] = useState({});

	const [loadingAgency, setLoadingAgency] = useState(false);
	const [loadingStripe, setLoadingStripe] = useState(false);
	const [filteredDocumentRequirements, setFilteredDocumentRequirements] = useState([]);

	const [pendingUploadedDocuments, setPendingUploadedDocuments] = useState([]);
	const [approvedUploadedDocuments, setApprovedUploadedDocuments] = useState([]);
	const [rejectedUploadedDocuments, setRejectedUploadedDocuments] = useState([]);

	const { authCarePro } = useAuth();

	const {
		data: documentRequirements,
		isLoading: isLoadingDocumentRequirements,
		refetch: refetchDocumentRequirements,
	} = useFetchDocumentRequirements();

	const {
		data: uploadedDocuments,
		isLoading: isLoadingUploadedDocuments,
		refetch: refetchUploadedDocuments,
	} = useFetchUploadedDocumentsQuery();

	const careProUploadDocuments = useCareProUploadDocumentMutation();
	const uploadStripeDocumentsMutation = useUploadStripeDocumentsMutation();

	console.log('documentRequirements', documentRequirements);
	console.log('uploadedDocuments', uploadedDocuments);
	useEffect(() => {
		if (documentRequirements && uploadedDocuments) {
			const filteredRequirements = documentRequirements.filter(
				(documentRequirement) =>
					!uploadedDocuments.some(
						(uploadedDocument) =>
							uploadedDocument.documentRequirement.id === documentRequirement.id
					)
			);
			setFilteredDocumentRequirements(documentRequirements);

			// filter uploadedDocuments for pending, approved, and rejected
			const pendingDocuments = [];
			const approvedDocuments = [];
			const rejectedDocuments = [];

			uploadedDocuments.forEach((document) => {
				if (document.status === 'pending') {
					pendingDocuments.push(document);
				} else if (document.status === 'approved') {
					approvedDocuments.push(document);
				} else if (document.status === 'rejected') {
					rejectedDocuments.push(document);
				}
			});

			setPendingUploadedDocuments(pendingDocuments);
			setApprovedUploadedDocuments(approvedDocuments);
			setRejectedUploadedDocuments(rejectedDocuments);
		}
	}, [documentRequirements, uploadedDocuments]);

	const fileChangeHandler = (event, documentRequirementId, type) => {
		const allowedFileTypes = [
			'image/heic',
			'image/webp',
			'image/png',
			'image/bmp',
			'image/jpg',
			'image/jpeg',
			'application/pdf',
		];

		const file = event.target.files[0];

		if (!file) return;

		if (!allowedFileTypes.includes(file.type)) {
			alert('File type not supported. Please upload a valid file.');
			return;
		}

		if (type === 'agency') {
			setFilesToUploadAgency((prev) => ({
				...prev,
				[documentRequirementId]: {
					...prev[documentRequirementId],
					file: file,
				},
			}));
		} else {
			setFilesToUploadStripe((prev) => ({
				...prev,
				[documentRequirementId]: {
					...prev[documentRequirementId],
					file: file,
				},
			}));
		}
	};

	const clearFileSelection = (documentRequirementId, type) => {
		if (type === 'agency') {
			setFilesToUploadAgency((prev) => {
				const updatedFiles = { ...prev };
				delete updatedFiles[documentRequirementId];
				return updatedFiles;
			});
		} else {
			setFilesToUploadStripe((prev) => {
				const updatedFiles = { ...prev };
				delete updatedFiles[documentRequirementId];
				return updatedFiles;
			});
		}
	};

	const validateExpirationDates = (files, requirements) => {
		for (const documentRequirement of requirements) {
			const fileData = files[documentRequirement.id];

			if (fileData && documentRequirement.requires_expiration) {
				if (!fileData.expirationDate) {
					alert(`Expiration date is required for ${documentRequirement.name}`);
					return false;
				}
			}
		}
		return true;
	};

	const handleUploadDocuments = async (
		filesLocations,
		doc_request_id,
		setLoading,
		expiration_date
	) => {
		try {
			await careProUploadDocuments.mutateAsync({
				documentUrls: filesLocations,
				requiredDocumentId: doc_request_id,
				expirationDate: expiration_date || null,
			});

			refetchDocumentRequirements();
			refetchUploadedDocuments();

			return true;
		} catch (err) {
			setLoading(false);
			console.log('handleUploadCallback - err', err);
		}
	};
	const uploadAllStripeDocuments = async () => {
		const requirements = authCarePro.stripeStatus.stripe_data.currently_due;

		if (!validateExpirationDates(filesToUploadStripe, requirements)) {
			return;
		}

		try {
			setLoadingStripe(true);

			for (const documentRequirementId of Object.keys(filesToUploadStripe)) {
				const fileData = filesToUploadStripe[documentRequirementId]?.file;

				if (fileData) {
					const data = new FormData();
					data.append('file', fileData);
					data.append('purpose', 'identity_document');

					const fileResult = await fetch('https://uploads.stripe.com/v1/files', {
						method: 'POST',
						headers: {
							Authorization: `Bearer ${process.env.STRIPE_PUBLIC_KEY}`,
						},
						body: data,
					});

					const fileDataResult = await fileResult.json();
					console.log(fileDataResult);
					try {
						await uploadStripeDocumentsMutation.mutateAsync({
							frontImg: fileDataResult.id,
						});
					} catch (error) {
						console.error(
							`Updating care pro document with ID ${documentRequirementId} failed.`,
							error
						);
					}
				}
			}

			setLoadingStripe(false);
		} catch (error) {
			console.error('Error uploading Stripe documents:', error);
			setLoadingStripe(false);
		}
	};

	const getSelectedFileName = (documentRequirementId, type) => {
		return type === 'agency'
			? filesToUploadAgency[documentRequirementId]?.file?.name || 'Upload File(s)'
			: filesToUploadStripe[documentRequirementId]?.file?.name || 'Upload File(s)';
	};

	if (isLoadingDocumentRequirements || isLoadingUploadedDocuments) {
		return <p>Fetching document requirements</p>;
	}

	return (
		<StyledDocumentsPage>
			<StyledDocumentsContainer>
				<StyledDocumentsWrapper>
					<StyledTitle>AGENCY DOCUMENT REQUESTS Mew</StyledTitle>
					<StyledDocumentRequest>
						{filteredDocumentRequirements.map((documentRequirement, index) => {
							return (
								<UploadTile
									key={index}
									documentRequirement={documentRequirement}
									uploaded={uploadedDocuments}
									handleUploadDocuments={handleUploadDocuments}
								/>
							);
						})}
					</StyledDocumentRequest>
				</StyledDocumentsWrapper>

				{authCarePro.stripeStatus.stripe_data.currently_due.filter((documentRequirement) =>
					documentRequirement.endsWith('.document')
				).length > 0 && (
					<StyledDocumentsWrapper>
						<StyledTitle>STRIPE DOCUMENT REQUESTS</StyledTitle>
						<StyledDocumentRequest>
							{authCarePro.stripeStatus.stripe_data.currently_due
								.filter((documentRequirement) =>
									documentRequirement.endsWith('.document')
								)
								.map((documentRequirement, index) => (
									<StyledDocumentCard key={index}>
										<StyledDocumentTitle>
											{stripeDocumentRequests(documentRequirement)}
										</StyledDocumentTitle>

										<StyledDocumentUploadArea>
											<StyledFileInput
												type='file'
												id={`file-upload-stripe-${index}`}
												onChange={(e) =>
													fileChangeHandler(e, index, 'stripe')
												}
											/>
											<StyledUploadFilesText>
												{getSelectedFileName(index, 'stripe')}
											</StyledUploadFilesText>

											{filesToUploadStripe[index]?.file && (
												<StyledClearFileIcon
													onClick={() =>
														clearFileSelection(index, 'stripe')
													}
												>
													✖
												</StyledClearFileIcon>
											)}
										</StyledDocumentUploadArea>
									</StyledDocumentCard>
								))}
						</StyledDocumentRequest>

						<StyledButtonContainer>
							<PrimaryButton
								size='small'
								onClick={uploadAllStripeDocuments}
								disabled={
									loadingStripe || Object.keys(filesToUploadStripe).length === 0
								}
							>
								{loadingStripe ? 'Uploading...' : 'Upload Stripe Documents'}
							</PrimaryButton>
						</StyledButtonContainer>
					</StyledDocumentsWrapper>
				)}
			</StyledDocumentsContainer>

			{pendingUploadedDocuments.length > 0 && (
				<DocumentsList title='Pending Approval' documents={pendingUploadedDocuments} />
			)}
			{rejectedUploadedDocuments.length > 0 && (
				<DocumentsList
					title='Rejected Documents'
					rejected
					documents={rejectedUploadedDocuments}
				/>
			)}
			{approvedUploadedDocuments.length > 0 && (
				<DocumentsList title='Approved Documents' documents={approvedUploadedDocuments} />
			)}
		</StyledDocumentsPage>
	);
};

export default DocumentsPage;
