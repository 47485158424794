import styled from 'styled-components';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Colors from 'colors';
import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import {
	faUserMd,
	faChild,
	faCircle,
	faDotCircle,
	faChevronRight,
	faUserNurse,
} from '@fortawesome/free-solid-svg-icons';
import Icon from 'AppComp/Icon';

export const StyledFormPage = styled.div`
	min-height: 100vh;
	max-width: 1000px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-items: flex-start;
	gap: 24px;

	margin: auto;
`;

export const StyledPageHeadingContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const StyledPageHeading = styled.h1`
	color: ${Colors.primary.primary};
	font-family: 'Bellota';
	letter-spacing: 2px;
	margin: 0 auto 20px auto;
	line-height: 1.4;
	font-size: 1.8em;
`;

export const PageHeading = (props) => {
	const { children, styles } = props;

	console.log('styles', styles);
	return (
		<Title nubtitle color={Colors.primary.primary} style={styles}>
			{children}
		</Title>
	);
};

// padding should be 40 but in less than 700 width should be 20
// width should be full until 700px and then max 1000px
export const FormDisplaySection = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	padding: 20px;
	gap: 15px;
	@media (min-width: 700px) {
		padding: 40px;
	}
	margin: 0 auto 0 auto;
	width: 100%;
	max-width: 670px;
`;

export const FormItemsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 50px;
	width: 100%;
`;

export const FormItemCon = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
	justify-content: space-between;
	border-radius: 8px;
	border: 1px solid rgba(43, 105, 166, 0.3);
	align-items: flex-start;
	height: 140px;
	padding: 20px;
	cursor: pointer;
`;

export const IconCon = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	height: 60px;
	width: 60px;
	background-color: ${Colors.primary.primary};
`;

export const FormItemTextCon = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;
export const FormItem = (props) => {
	const { id, template_label, template_type, description } = props;

	const history = useHistory();

	const handleTileClick = () => {
		// on click send the user to the form template /admin/form/:formId
		history.push(`/admin/form/${id}`);
	};
	return (
		<FormItemCon onClick={() => handleTileClick()}>
			<IconCon>
				<Icon icon={faUserNurse} size='2x' color={'white'} />
			</IconCon>
			<FormItemTextCon>
				<Text fontWeight='600' color={Colors.primary.primary} largeText>
					{template_label}
				</Text>
				<Text fontWeight='300' largeText>
					{description}
				</Text>
			</FormItemTextCon>
			<Icon icon={faChevronRight} size='2x' color={Colors.primary.primary} />
		</FormItemCon>
	);
};
