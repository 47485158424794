import { z } from 'zod';

const infoFormValidationSchema = z.object({
	cr_first_name: z.string().min(1, 'First name is required.'),
	cr_last_name: z.string().min(1, 'Last name is required.'),
	cr_display_name: z.string().min(1, 'Display name is required.'),
	cr_dob: z.string().nonempty('Date of birth is required.'),
	cr_gender: z.enum(
		['male', 'female', 'transgender', 'non-binary', 'prefer-not-to-respond'],
		'Gender is required.'
	),
});

export default infoFormValidationSchema;
