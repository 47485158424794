import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

import { PrimaryButton, SecondaryButton } from '../../../../../corev2/Buttons';
import PageHeading from '../../../core/components/PageHeading';
import { CareProTile } from '../../../../../corev2/Tiles';
import JobOfferGuide from '../../components/JobOfferGuide';
import CareNeeds from '../../components/CareNeeds';
import ShiftSelector from '../../components/ShiftSelector';

import { JobPostProvider } from '../../context/JobPostContext';

import { jobPostsRoutes } from '../../routes/constants';
import { profileRoutes } from '../../../profiles/routes/constants';

import { useAuth } from '../../../core/hooks/useAuth';
import { useJobPost } from '../../hooks/useJobPost';

import { useFetchSelectedCareProQuery } from '../../api/queries/useFetchSelectedCareProQuery';
import { useOfferCarePro } from '../../api/mutations/useOfferCarePro';

import Notification from '../../../../../client/modules/core/lib/notification';
import { notifications } from '../../../../../client/modules/core/constants';

import {
	StyledJobPostOfferPage,
	StyledJobSection,
	StyledAction,
	StyledActionHeading,
	StyledStatusWrapper,
	StyledStatusIconContainer,
	StyledStatus,
	StyledActionsContainer,
	StyledOfferContainer,
	StyledCalculatorContainer,
	StyledCalculatorValueContainer,
	StyledCalculatorWrapper,
	StyledCalculatorButton,
	StyledCalculatorIconContainer,
	StyledCalculatedValueLabel,
	StyledCalculatedValue,
	StyleOfferButtonsContainer,
	StyledMainContainer,
	StyledCareProsContainer,
} from './styles';

const JobPostOfferPageContent = () => {
	const [hourlyRate, setHourlyRate] = useState(5);
	const [isShiftSelectionModalOpen, setIsShiftSelectionModalOpen] = useState(false);

	const history = useHistory();
	const { careProId } = useParams();

	const { authUser } = useAuth();
	const { jobPost, isLoadingJobPost } = useJobPost();

	const { data: carePro, isLoading: isLoadingCarePro } = useFetchSelectedCareProQuery(careProId);
	const offerCarePro = useOfferCarePro();

	if (isLoadingJobPost || isLoadingCarePro) {
		return <>Loading Job Post</>;
	}

	const incrementPrice = () => {
		setHourlyRate((prevValue) => prevValue + 1);
	};

	const deccrementPrice = () => {
		setHourlyRate((prevValue) => prevValue - 1);
	};

	const shiftSelectionModalHandler = () => {
		setIsShiftSelectionModalOpen(!isShiftSelectionModalOpen);
	};

	const offerCareProHandler = async (startDate, endDate, incomingShifts) => {
		try {
			let shifts = [];
			if (incomingShifts.length > 0) {
				incomingShifts.map((incomingShift) => {
					shifts.push({
						start_date: incomingShift.start_date,
						start_time: incomingShift.start_time,
						end_date: incomingShift.end_date,
						end_time: incomingShift.end_time,
						schedule_id: incomingShift.schedule_id,
						job_post_id: jobPost.id,
						care_plan_id: authUser.carePlan.id,
					});
				});
			}

			await offerCarePro.mutateAsync({
				startDate,
				endDate,
				jobPostId: jobPost.id,
				careProId: carePro.id,
				hourlyRate,
				shifts,
			});

			Notification(notifications.jobPosts.careProOffered);

			history.push(jobPostsRoutes.jobPost(jobPost.id));
		} catch (error) {
			console.error('Offer care pro failed', error);
		}
	};

	const viewCareProHandler = (careProId) => {
		history.push(profileRoutes.careProProfile(careProId));
	};

	return (
		<StyledJobPostOfferPage>
			{isShiftSelectionModalOpen && (
				<ShiftSelector
					schedules={jobPost.schedules}
					shiftSelectionModalHandler={shiftSelectionModalHandler}
					offerCareProHandler={offerCareProHandler}
				/>
			)}

			<PageHeading>
				Make offer to: {carePro.displayName} for job "{jobPost.title}"
			</PageHeading>

			<JobOfferGuide />

			<StyledJobSection>
				<StyledActionsContainer>
					<StyledAction>
						<StyledActionHeading>Status</StyledActionHeading>

						<StyledStatusWrapper>
							<StyledStatusIconContainer>
								<FontAwesomeIcon icon={faExclamationCircle} size='lg' />
							</StyledStatusIconContainer>

							<StyledStatus>Pending</StyledStatus>
						</StyledStatusWrapper>
					</StyledAction>

					<StyledAction>
						<StyledActionHeading>Offer</StyledActionHeading>

						<StyledOfferContainer>
							<StyledCalculatorContainer>
								<StyledCalculatorWrapper>
									<StyledCalculatorButton onClick={deccrementPrice}>
										<StyledCalculatorIconContainer>
											<FontAwesomeIcon icon={faMinus} size='sm' />
										</StyledCalculatorIconContainer>
									</StyledCalculatorButton>
									<StyledCalculatorValueContainer>
										<StyledCalculatedValue>
											${hourlyRate?.toFixed(2)}
										</StyledCalculatedValue>
										<StyledCalculatedValueLabel>
											Per Hour
										</StyledCalculatedValueLabel>
									</StyledCalculatorValueContainer>
									<StyledCalculatorButton onClick={incrementPrice}>
										<StyledCalculatorIconContainer>
											<FontAwesomeIcon icon={faPlus} size='sm' />
										</StyledCalculatorIconContainer>
									</StyledCalculatorButton>
								</StyledCalculatorWrapper>
							</StyledCalculatorContainer>

							<StyleOfferButtonsContainer>
								<PrimaryButton size='small' onClick={shiftSelectionModalHandler}>
									Send Offer
								</PrimaryButton>
								<SecondaryButton size='small'>Chat</SecondaryButton>
							</StyleOfferButtonsContainer>
						</StyledOfferContainer>
					</StyledAction>
				</StyledActionsContainer>

				<StyledMainContainer>
					<StyledCareProsContainer>
						<StyledActionHeading>Care Pros</StyledActionHeading>

						<CareProTile
							key={carePro.id}
							id={carePro.id}
							displayName={carePro.displayName}
							rating={carePro.averageReview}
							hourlyRate={carePro.hourly_rate}
							professionalStatement={carePro.professional_statement}
							viewCareProHandler={viewCareProHandler}
						/>
					</StyledCareProsContainer>

					<StyledCareProsContainer>
						<StyledActionHeading>Care Needs</StyledActionHeading>

						<CareNeeds />
					</StyledCareProsContainer>
				</StyledMainContainer>
			</StyledJobSection>
		</StyledJobPostOfferPage>
	);
};

const JobPostOfferPage = () => {
	return (
		<JobPostProvider>
			<JobPostOfferPageContent />
		</JobPostProvider>
	);
};

export default JobPostOfferPage;
