import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const CARE_PLAN_MODEL = `

`;
const updateCarePlanInfo = async ({ carePlanId, carePlanInfo }) => {
	try {
		const data = await api.graph({
			query: `mutation{
        updateCarePlanInfo(
          care_plan_id: ${carePlanId},
          care_plan_input: ${api.graphStringify(carePlanInfo)}
        ) 
        {
          id,
				  cc_first_name,
				  cc_last_name,
				  cc_phone_number,
				  cr_first_name,
				  cr_last_name,
				  cc_relation_to_cr,
          cr_display_name,
				  cr_dob,
				  cr_gender,
				  care_zip,
          	carePlanSet {
				  	id
				  }
				  careNeedsSet {
				 		id
				  }
        }
      }`,
		});

		return data.updateCarePlanInfo;
	} catch (err) {
		console.log('updateCarePlanInfo error', err);
		throw new Error(err);
	}
};

const useUpdateCarePlanInfo = () => {
	return useMutation({
		mutationKey: ['updateCarePlanInfo'],
		mutationFn: (values) => {
			return updateCarePlanInfo(values);
		},
	});
};

export default useUpdateCarePlanInfo;
