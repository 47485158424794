import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { FormContainer, Input, Label, Select, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import FormWrapper from '../../components/FormWrapper';
import InputContainer from '../../components/InputContainer';

import infoFormValidationSchema from './validation';

const InfoForm = (props) => {
	const { serverError, initialValues, infoFormHandler } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: async (values) => infoFormHandler(values),
		validationSchema: toFormikValidationSchema(infoFormValidationSchema),
		enableReinitialize: true,
	});

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<Label> First Name</Label>

					<Input
						name='cr_first_name'
						type='text'
						onChange={formik.handleChange}
						placeholder='First Name'
						value={formik.values.cr_first_name}
					/>

					{formik.errors.cr_first_name && (
						<ErrorMessage> {formik.errors.cr_first_name} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> Last Name</Label>

					<Input
						name='cr_last_name'
						type='text'
						onChange={formik.handleChange}
						placeholder='Last Name'
						value={formik.values.cr_last_name}
					/>

					{formik.errors.cr_last_name && (
						<ErrorMessage> {formik.errors.cr_last_name} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> Display Name</Label>

					<Input
						name='cr_display_name'
						type='text'
						onChange={formik.handleChange}
						placeholder='Display Name'
						value={formik.values.displayName}
					/>

					{formik.errors.cr_display_name && (
						<ErrorMessage> {formik.errors.cr_display_name} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> Date of birth </Label>

					<Input
						name='cr_dob'
						type='date'
						onChange={formik.handleChange}
						placeholder='Date of Birth'
						value={formik.values.cr_dob}
					/>

					{formik.errors.cr_dob && <ErrorMessage> {formik.errors.cr_dob} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Label> Gender </Label>

					<Select
						name='cr_gender'
						onChange={formik.handleChange}
						value={formik.values.gender}
					>
						<option>Gender</option>
						<option value='male'>Male</option>
						<option value='female'>Female</option>
						<option value='transgender'>Transgender</option>
						<option value='non-binary'>Non-binary / Non-conforming</option>
						<option value='prefer-not-to-respond'>Prefer not to responsd</option>
					</Select>

					{formik.errors.cr_gender && (
						<ErrorMessage> {formik.errors.cr_gender} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<PrimaryButton size='small' type='submit'>
						Save
					</PrimaryButton>

					{serverError && <ErrorMessage> {serverError} </ErrorMessage>}
				</InputContainer>
			</FormWrapper>
		</FormContainer>
	);
};

export default InfoForm;
