import React from 'react';
import styled from 'styled-components';

// Container for the entire grid
const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr; /* 2 cards per row on larger screens */
	gap: 20px;
	//   padding: 20px;
	background-color: #e6f2f8;

	@media (max-width: 768px) {
		grid-template-columns: 1fr; /* 1 card per row on smaller screens */
	}
`;

// Each document card
const DocCard = styled.div`
	background: #f0f8fd;
	border-radius: 10px;
	padding: 15px;
	display: flex;
	flex-direction: column;
	border: 1px solid rgba(43, 105, 166, 0.3);
	position: relative;
`;

// Title of the document
const DocTitle = styled.h3`
	margin: 0;
	font-size: 15px;
	line-height: 1.7;
	color: #2b69a3;
`;

// Main wrapper for the document upload section
export const StyledShiftWrapper = styled.div`
	width: 100vw;
	background-color: #edf6fb;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding: 40px;

	@media (max-width: 768px) {
		padding: 10px;
	}

	@media (max-width: 480px) {
		padding: 5px;
	}
`;

// Container for the entire document upload section
export const StyledShiftContainer = styled.div`
	width: 100%;
	max-width: 1000px;
	box-sizing: border-box;
	padding: 20px;
	background-color: white;
	border: 1px solid rgba(43, 105, 166, 0.3);
	color: #2b69a3;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	margin-bottom: 24px;

	@media (max-width: 768px) {
		padding: 15px;
		max-width: 100%;
	}

	@media (max-width: 480px) {
		padding: 10px;
	}
`;

// Title for the document upload section
export const StyledTitle = styled.h3`
	font-size: 18px;
	font-weight: 700;
	color: #2b69a3;
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-bottom: 15px;
	text-align: left;

	@media (max-width: 480px) {
		font-size: 16px;
	}
`;

// Container for the document list
const DocListContainer = styled.div`
	background-color: #eaf4fc; /* Slightly darker background for the list */
	border-radius: 8px;
	padding: 20px;
	border: 1px solid #cbd4db; /* Border to separate from the background */
`;

// Each document item in the list
const DocItem = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px 0;
	font-size: 15px;
	line-height: 1.7;

	&:last-child {
		border-bottom: none; /* No border for the last item */
	}
`;

const DocDate = styled.span`
	font-size: 15px;
	color: #4a6f91; /* Date color */
`;

// Container for the shift details within the document upload section
export const StyledShiftDetails = styled(Container)`
	background-color: white;
	text-align: left;

	@media (max-width: 480px) {
	}
`;

const UploadedDocuments = () => {
	// Example document data
	const documents = [
		{ title: 'Doc 1', date: 'June 26, 2024 at 4:46 AM' },
		{ title: 'Doc 2', date: 'June 27, 2024 at 2:48 AM' },
		{ title: 'Doc 3', date: 'June 26, 2024 at 4:46 AM' },
		{ title: 'Doc 4', date: 'June 26, 2024 at 10:48 AM' },
	];
	return (
		<StyledShiftWrapper>
			<StyledShiftContainer>
				<StyledTitle>Uploaded Documents</StyledTitle>
				<StyledShiftDetails>
					<DocListContainer>
						{documents.map((doc, index) => (
							<DocItem key={index}>
								<DocTitle>{doc.title}</DocTitle>
								<DocDate>{doc.date}</DocDate>
							</DocItem>
						))}
					</DocListContainer>
				</StyledShiftDetails>
			</StyledShiftContainer>
		</StyledShiftWrapper>
	);
};

export default UploadedDocuments;
