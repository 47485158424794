import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { FormContainer, Input, Label, Select, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import FormWrapper from '../../components/FormWrapper';
import InputContainer from '../../components/InputContainer';

import logisticsFormValidationSchema from './validation';

import { usStates } from '../../../../../corev2/utils';

const LogisticsForm = (props) => {
	const { serverError, initialValues, logisticsFormHandler } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => logisticsFormHandler(values),
		validationSchema: toFormikValidationSchema(logisticsFormValidationSchema),
		enableReinitialize: true,
	});

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<Label> Address Line 1</Label>

					<Input
						name='address1'
						type='text'
						onChange={formik.handleChange}
						placeholder='Address 1'
						value={formik.values.address1}
					/>

					{formik.errors.address1 && (
						<ErrorMessage> {formik.errors.address1} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> Address Line 1</Label>

					<Input
						name='address2'
						type='text'
						onChange={formik.handleChange}
						placeholder='Address 2'
						value={formik.values.address2}
					/>

					{formik.errors.address2 && (
						<ErrorMessage> {formik.errors.address2} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> City</Label>

					<Input
						name='city'
						type='text'
						onChange={formik.handleChange}
						placeholder='City'
						value={formik.values.city}
					/>

					{formik.errors.city && <ErrorMessage> {formik.errors.city} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Label> Zip</Label>

					<Input
						name='zip'
						type='text'
						onChange={formik.handleChange}
						placeholder='ZIP Code'
						value={formik.values.zip}
					/>

					{formik.errors.zip && <ErrorMessage> {formik.errors.zip} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Label> State</Label>

					<Select name='state' onChange={formik.handleChange} value={formik.values.state}>
						{usStates.map((usState) => {
							return (
								<option key={usState} value={usState}>
									{usState}
								</option>
							);
						})}
					</Select>

					{formik.errors.state && <ErrorMessage> {formik.errors.state} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Label> Code to enter home</Label>

					<Input
						name='codeToEnterHome'
						type='text'
						onChange={formik.handleChange}
						placeholder='Code to Enter Home'
						value={formik.values.codeToEnterHome}
					/>

					{formik.errors.codeToEnterHome && (
						<ErrorMessage> {formik.errors.codeToEnterHome} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> Where to park</Label>

					<Input
						name='whereToPark'
						type='text'
						onChange={formik.handleChange}
						placeholder='Where to Park'
						value={formik.values.whereToPark}
					/>

					{formik.errors.whereToPark && (
						<ErrorMessage> {formik.errors.whereToPark} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> Extra info</Label>

					<Input
						name='extraInfo'
						type='text'
						onChange={formik.handleChange}
						placeholder='Extra Info'
						value={formik.values.extraInfo}
					/>
				</InputContainer>

				<InputContainer>
					<PrimaryButton size='small' type='submit'>
						Save
					</PrimaryButton>

					{serverError && <ErrorMessage> {serverError} </ErrorMessage>}
				</InputContainer>
			</FormWrapper>
		</FormContainer>
	);
};

export default LogisticsForm;
