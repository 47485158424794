//graphqlModelTypes.js
/**
	THIS FILE SHOULD  CONTAIN ALL THE GRAPHQL BASIC TYPES FOR FETCHING GRAPHQL TYPES
	LOOK HERE FOR REFFERANCE (https://github.com/setdev-core/set-web-wiki/wiki/GraphQL-Model-Fetching)

	- THIS FILE SHOULD BE SPLIT INTO SECTIONS FOR BETTER NAVIGATION
	- Sections is listed in the following order (should alwasy be in alphabetical order)
		- as a ref structure should be the same order as the api types

*/

/*********************
	Care Needs
*********************/
export const CARE_NEEDS_MODEL = `
	id,
	label,
	setData {
		id,
		label,
		required,
		description,
		trait_type,
		value {
			id,
			attribute_trait_id,
			attribute_set_id,
			trait_value,
			last_edited,	
		}
	}
`;

export const MESSAGE_MODEL = `
	id,
	sent_by,
	sent_by_admin,
	text,
	timestamp,
	device_sent_from,
	type,
	sent_by,
	email_text
`;

export const CONVERSATION_MODEL = `
	id,
	user_type,
	unread_message,
	latest_message_timestamp,
	user_id,
	provider_id,
	admin_id,
	page,
	total_pages,
	latest_message

	user {
		id
		email,
		name,
		phone_number,
		
	}
	provider {
		id
		email,	
		displayName,
		first_name,
		last_name,
	}
	admin {
		id
		email,
		name
	}

	messages {
		${MESSAGE_MODEL}
	}
`;

/*********************
	AdminType
*********************/

export const ADMIN_MODEL = `
	id,
	email,
	name
`;

/*********************
	CarePlanType
*********************/

export const CAREPLAN_MODEL_V2 = `
	id,


	careNeedsSet {
      ${CARE_NEEDS_MODEL}
  	}
	carePlanSet {
		${CARE_NEEDS_MODEL}
	}
	
	
	completed_care_plan
	completed_platform_onboard
	completed_registry_onboard

	coordinator {
		id,
		email,
		name,
		phone_number,
		stripe_customer_id,
	}

	jobs {
		id,
		hourly_amount,
		job_post_id,
		care_plan_id,
		provider_id,
		status,
		offer_status,
		job_status,
		agency_fee,
		created_at,	
	
	}
`;

/*********************
	Reviews
*********************/
export const PROVIDER_REVIEW_MODEL = `
	id,
	rating,
	comment,
	submission_timestamp
`;

/*********************
	PatientModel
*********************/

export const PATIENT_MODEL = `
	id,
	email,
	name,
	phone_number,
	stripe_customer_id,
	created_timestamp,
	address,
	stripe_token,
	
	
`;

export const PATIENT_MODEL_V2 = `
	id,
	email,
	name,
	phone_number,
	stripe_customer_id,
	created_timestamp,
	address,

	conversation_id,
	care_plan_id,
`;

export const PATIENT_INFO_CHANGE_MODEL = `
	id,
	property_name,
	new_value,
	created_timestamp,
	updated_timestamp,
	status,
`;

export const PAGINATED_PROVIDERS_MODEL_V2 = `
	page,
	totalPages,
`;

export const PAYMENT_TYPE_MODEL = `
	id,
	stripe_payment_intent_id,
	hourly_rate,
	total_time_worked,
	subtotal,
	stripe_fee,
	fyi_share,
	provider_payout,
	timestamp,
	stripe_error,
	stripe_error_code,
	stripe_error_message,
	failed_payment_attempts,
	agency_fee_hourly,
	payment_status,
`;

/*********************
	PaymentMethodType
*********************/
export const PAYMENT_METHOD_TYPE_MODEL = `
	id,
	last_4,
	cvc_check,
	brand,
	type,
	exp_month,
	exp_year,
	default_card,
`;

/*********************
	PayoutMethodType
*********************/
export const PAYOUT_METHOD_MODEL = `
	id,
	last_4,
	cvc_check,
	brand,
	type,
	exp_month,
	exp_year,
	default_card
`;

/*********************
	PayoutType
*********************/
export const PAYOUT_MODEL = `
	amount,
	created_at,
	failure_code,
	status,
	type
`;

/*********************
	ProviderType
*********************/

export const PROVIDER_MODEL_V2 = `
	id,
	email,
	displayName,
	gender,
	image_url,
	phone_number,
	background_check_status,
	professional_statement,
	first_name,
	last_name,
	care_type,
	account_status,
	display_name,
	hourly_rate,
	live_in_rate,

	careAbilitySet {
		${CARE_NEEDS_MODEL}
	}
`;

export const PROVIDER_MODEL = `
	id,
	email,
	displayName,
	gender,
	image_url,
	phone_number,
	background_check_status,
	professional_statement,
	first_name,
	last_name,
	care_type,
	account_status,
	
	,
	hourly_rate,
	live_in_rate
`;

export const UNMATCHED_PROVIDER_MODEL_V2 = `
	id,
	displayName,
	gender,
	image_url,
	background_check_status,
	professional_statement,
	care_type,
	account_status,
	display_name,
	hourly_rate,
	live_in_rate,

	careAbilitySet {
		${CARE_NEEDS_MODEL}
	}
`;

/*********************
	UserType
*********************/
export const USER_MODEL = `
	id,
	email,
	name,
	phone_number,
	stripe_customer_id,
	
`;
//badger@btc.com

export const USER_MODEL_V2 = `
	id,
	email,
	name,
	phone_number,
	stripe_customer_id,
	created_timestamp,
	twilio_verify_info,
	email_verification,
	payment_method_verification,
	payment_intent_failure_status,
	text_verification,
	email_verification,

	carePlan {
		id,
		careNeedsSet {
    	  	${CARE_NEEDS_MODEL}
  		}
		carePlanSet {
			${CARE_NEEDS_MODEL}
		}
	}
`;

export const UNMATCHED_USER_MODEL_V2 = `
	id,
	name,
	created_timestamp,
	twilio_verify_info,
	email_verification,
	payment_method_verification,
	payment_intent_failure_status,
	text_verification,
	email_verification,

	carePlan {
		careNeedsSet {
    	  	${CARE_NEEDS_MODEL}
  		}
		carePlanSet {
			${CARE_NEEDS_MODEL}
		}
	}
`;

/*********************

*********************/

/*********************

*********************/

export const VERIFICATION_MODEL = `
	valid,
	details_submitted,
	charges_enabled,
	card_payments,
	transfers,
	payouts_enabled,
	error_code,
	error_reason,
	admin_verification,
	
	valid_email,
	professional_statement,
	profile_attributes,
	
`;

export const STRIPE_VERIFICATION_INFO_MODEL = `
	tos_acceptance_ip,
	tos_acceptance_ip_date,
	external_account,
	first_name,
	last_name,
	dob_day,
	dob_month,
	dob_year,
	address_line1,
	address_line2,
	address_city,
	address_state,
	address_postal_code,
	email,
	ssn_last_4,
	currently_due,
	token
	front_image
	back_image
`;

export const AGENCY_CARE_PLAN_MODEL = `
 	id,
 	registry_id,
 	care_plan_options,
 	last_updated
`;

export const DOCUMENT_REQUIREMENTS = `
	id,
	registry_id,
	created_at,
	user_type,
	name,
	required,
	uploaded_by,
	requires_expiration
`;

export const DOCUMENT_MODEL = `
id,
user_id,
uploaded_by,
document_url,
uploaded_date,
document_name,
status,
notes,
rejection_notes,

expiration_date,
uploadedDocuments {
	id,
	document_url,
	uploaded_date,
	document_id,
}
documentRequirement {
	${DOCUMENT_REQUIREMENTS}
}
provider {
	id,
	display_name
}
carePlan {
	id,
	
}


`;

export const CAREPRO_DOCUMENT_MODEL = `
	id,
	user_id,
	uploaded_by,
	document_url,
	uploaded_date,
	document_name,
	status,
	notes,
	rejection_notes,

	expiration_date,
	uploadedDocuments {
		id,
		document_url,
		uploaded_date,
	}
`;
export const SINGLE_DOCUMENT_MODEL = `
id,
user_id,
uploaded_by,
document_url,
uploaded_date,
document_name,
status,
notes,
rejection_notes,

expiration_date,
uploadedDocuments {
	id,
	document_url,
	uploaded_date,
}

provider {
	${PROVIDER_MODEL_V2},
}
carePlan {
	${CAREPLAN_MODEL_V2}
}


`;

export const CORE_WORK_TIME_FRAME = `
	id,
	start_time,
	end_time,
	type_enum,
	active_status_enum,
	pattern_id,
	pattern_key,
	job_post_id,
	job_id,
	parent_id,
`;

export const CORE_JOB_MODEL = `
	id,
	hourly_amount,
	job_post_id,
	care_plan_id,
	provider_id,
	status,
	offer_status,
	job_status,
	agency_fee,
	created_at,
	carePlan {
		${CAREPLAN_MODEL_V2}
	}


`;

export const SHIFT_BARE_MODEL = `
id,
status,
clock_in_time,
clock_out_time,
start_time,
end_time,
client_signature_url,
location_lat,
location_long,
clock_out_location_lat,
clock_out_location_long,
notes,
care_plan_options,
rating,
confirmed_shift_time,
`;
export const SHIFT_MODEL = `
	id,
	status,
	clock_in_time,
	clock_out_time,
	start_time,
	end_time,
	client_signature_url,
	location_lat,
	location_long,
	clock_out_location_lat,
	clock_out_location_long,
	notes,
	care_plan_options,
	rating,
	confirmed_shift_time,
	job {
		${CORE_JOB_MODEL}
	}
	shiftCareActivitySet {
      ${CARE_NEEDS_MODEL}
  	}
`;

export const CORE_APPLICATION_MODEL = `
	id,
	status,
	provider_id,
	job_post_id,
	jobPost {
		id,
		budget_low,
		budget_high,
		status,
		care_plan_id,
		created_at,
		workTimeFrames {
			${CORE_WORK_TIME_FRAME}
		}
		carePlan {
			${CAREPLAN_MODEL_V2}
		
		}
	}
`;

export const CORE_JOB_POST_MODEL = `
	id,
	budget_low,
	budget_high,
	status,
	care_plan_id,
	created_at,
	edited_filter,
	workTimeFrames {
		${CORE_WORK_TIME_FRAME}
	}
	jobs {
		${CORE_JOB_MODEL}
		provider {
			${UNMATCHED_PROVIDER_MODEL_V2}
		}
	}
	applications {
		${CORE_APPLICATION_MODEL}
		provider {
			${PROVIDER_MODEL}
		}
	}
	carePlan {
		${CAREPLAN_MODEL_V2}
		workTimeFrames {
			${CORE_WORK_TIME_FRAME}
		}
	}
`;

export const DATA_SET_MODEL = `
	id,
	label,
	setData {
		id,
		label,
		required,
		description,
		trait_type,
		value {
			id,
			attribute_trait_id,
			attribute_set_id,
			trait_value,
			last_edited,
		}
	}
`;

export const STRIPE_STATUS_MODEL = `
	id,
	charges_enabled,
	card_payments,
	transfers_enabled,
	payouts_enabled,
	provider_id,
	status,
	stripe_data {
		tos_acceptance_ip,
		tos_acceptance_ip_date,
		external_account,
		first_name,
		last_name,
		dob_day,
		status,
		dob_month,
		dob_year,
		address_line1,
		address_line2,
		address_city,
		address_state,
		address_postal_code,
		email,
		ssn_last_4,
		token,
		front_image,
		back_image,
		currently_due,
		requested_data_errors{
			code,
			reason,
			requirement
		}
	}
`;

export const CARE_PRO_REGISTRY_STATUS_MODEL = `
	id,
	registry_id,
	provider_id,
	documents_approval,
	registry_approval,
	platform_onboard,
`;

export const CAREPR0_PAYOUT_METHOD = `
	id,
	type,
	card {
		id
		last_4,
		cvc_check,
		brand,
		type,
		exp_month,
		exp_year
	}
	bank {
		id,,
		last_4,
		status,
		bank_name
	}
	default_method
`;
