import React from 'react';

import DocumentUpload from '../../components/DocumentUpload';
import AgencyDocuments from '../../components/AgencyDocuments';
import PendingApproval from '../../components/PendingApproval';
import UploadedDocuments from '../../components/UploadedDocuments';

const DocumentsPage = () => {
	return (
		<>
			<DocumentUpload />
			<AgencyDocuments />
			<PendingApproval />
			<UploadedDocuments />
		</>
	);
};

export default DocumentsPage;
