import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import JobStats from '../../components/JobStats';
import JobPost from '../../components/JobPost';

import { jobPostsRoutes } from '../../routes/constants';

import { useAuth } from '../../../core/hooks/useAuth';
import { useFetchRegistryJobPostsQuery } from '../../api/queries/useFetchRegistryJobPostsQuery';
import { useGenerateJobPostContent } from '../../hooks/useGenerateJobPostsContent';

import { StyledJobPostsPage, StyledJobPostsContainer } from './styles';

const JobPostsPage = () => {
	const [jobPosts, setJobPosts] = useState([]);
	const history = useHistory();

	const { authCarePro } = useAuth();
	const { data } = useFetchRegistryJobPostsQuery(1); // registry_id is 1

	useEffect(() => {
		if (data) {
			setJobPosts(data);
		}
	}, [data]);

	const { filteredJobPosts, stats } = useGenerateJobPostContent(jobPosts, authCarePro?.id);

	const changeJobStatusParam = (status) => {
		const searchParams = new URLSearchParams(location.search);
		searchParams.set('type', status);
		history.push({
			pathname: location.pathname,
			search: searchParams.toString(),
		});
	};

	const goToJobPostPage = (jobPostId) => {
		history.push(jobPostsRoutes.jobPost(jobPostId));
	};

	return (
		<StyledJobPostsPage>
			<JobStats stats={stats} changeJobStatusParam={changeJobStatusParam} />

			<StyledJobPostsContainer>
				{filteredJobPosts.map((jobPost) => {
					return (
						<JobPost
							key={jobPost.id}
							title={jobPost.title}
							description={jobPost.description}
							goToJobPostPage={() => goToJobPostPage(jobPost.id)}
						/>
					);
				})}
			</StyledJobPostsContainer>
		</StyledJobPostsPage>
	);
};

export default JobPostsPage;
