import React from 'react';
import styled from 'styled-components';

// Container for the entire grid
const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr); /* 2 cards per row on larger screens */
	gap: 20px;
	//   padding: 20px;
	background-color: #e6f2f8;

	@media (max-width: 768px) {
		grid-template-columns: 1fr; /* 1 card per row on smaller screens */
	}
`;

// Each document card
const DocCard = styled.div`
	background: white;
	border: 1px solid #cbd4db;
	border-radius: 10px;
	padding: 15px;
	display: flex;
	flex-direction: column;
	border: 1px solid rgba(43, 105, 166, 0.3);
	position: relative;
`;

// Title of the document
const DocTitle = styled.h3`
	margin: 0;
	font-size: 1.1em;
	line-height: 1.4;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: #2b69a3;
`;

// Upload area
const UploadArea = styled.label`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
	margin-top: 10px;
	margin-bottom: 20px;
	border: 1px solid rgba(43, 105, 166, 0.3);

	background-color: #f0f8fd;
	border-radius: 8px;
	cursor: pointer;
	color: #1e5a8e;
	font-size: 16px;
	text-align: center;
`;

const TextArea = styled.label`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
	margin-top: 10px;
	margin-bottom: 20px;
	border: 1px solid rgba(43, 105, 166, 0.3);

	background-color: #f0f8fd;
	border-radius: 8px;
	cursor: pointer;
	color: #1e5a8e;
	font-size: 16px;
	text-align: center;
	padding: 20px;
	text-align: left;
`;

// Hidden file input
const HiddenInput = styled.input`
	display: none;
`;

// Label for the expiration date input
const ExpirationLabel = styled.label`
	margin-bottom: 5px;
	font-size: 14px;
	color: #6c7a89;
`;

// Expiration date input
const ExpirationInput = styled.input.attrs({ type: 'date' })`
	border: 1px solid #cbd4db;
	border-radius: 5px;
	padding: 8px 10px;
	font-size: 14px;
	color: #6c7a89;
	margin-bottom: 20px; /* Add space below the input */
`;

// Main wrapper for the document upload section
export const StyledShiftWrapper = styled.div`
	width: 100vw;
	background-color: #edf6fb;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding: 40px;

	@media (max-width: 768px) {
		padding: 10px;
	}

	@media (max-width: 480px) {
		padding: 5px;
	}
`;

// Container for the entire document upload section
export const StyledShiftContainer = styled.div`
	width: 100%;
	max-width: 1000px;
	box-sizing: border-box;
	padding: 20px;
	background-color: white;
	border: 1px solid rgba(43, 105, 166, 0.3);
	color: #2b69a3;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	margin-bottom: 24px;

	@media (max-width: 768px) {
		padding: 15px;
		max-width: 100%;
	}

	@media (max-width: 480px) {
		padding: 10px;
	}
`;

// Title for the document upload section
export const StyledTitle = styled.h3`
	font-size: 18px;
	font-weight: 700;
	color: #2b69a3;
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-bottom: 15px;
	text-align: left;

	@media (max-width: 480px) {
		font-size: 16px;
	}
`;

// Container for the shift details within the document upload section
export const StyledShiftDetails = styled(Container)`
	background-color: white;
	text-align: left;

	@media (max-width: 480px) {
	}
`;

const AgencyDocuments = () => {
	return (
		<StyledShiftWrapper>
			<StyledShiftContainer>
				<StyledTitle>Agency Document Request</StyledTitle>
				<StyledShiftDetails>
					{Array.from({ length: 1 }).map((_, index) => (
						<DocCard key={index}>
							{/* <CheckBox /> */}
							<DocTitle>DOC {index + 1}</DocTitle>
							<UploadArea>
								<HiddenInput type='file' id={`file-upload-${index}`} />
								<span>Upload File(s)</span>
							</UploadArea>
							<ExpirationLabel htmlFor={`expiration-date-${index}`}>
								Expiration Date
							</ExpirationLabel>
							<ExpirationInput id={`expiration-date-${index}`} />
						</DocCard>
					))}
					{Array.from({ length: 4 }).map((_, index) => (
						<DocCard key={index}>
							{/* <CheckBox /> */}
							<DocTitle>DOC {index + 1}</DocTitle>
							<TextArea>
								Li Europan lingues es membres del sam familie. Lor separat
								existentie es un myth. Por scientie, musica, sport etc, litot
							</TextArea>
						</DocCard>
					))}
				</StyledShiftDetails>
			</StyledShiftContainer>
		</StyledShiftWrapper>
	);
};

export default AgencyDocuments;
