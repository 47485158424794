import { z } from 'zod';

const usStates = [
	'Alabama',
	'Alaska',
	'Arizona',
	'Arkansas',
	'California',
	'Colorado',
	'Connecticut',
	'Delaware',
	'Florida',
	'Georgia',
	'Hawaii',
	'Idaho',
	'Illinois',
	'Indiana',
	'Iowa',
	'Kansas',
	'Kentucky',
	'Louisiana',
	'Maine',
	'Maryland',
	'Massachusetts',
	'Michigan',
	'Minnesota',
	'Mississippi',
	'Missouri',
	'Montana',
	'Nebraska',
	'Nevada',
	'New Hampshire',
	'New Jersey',
	'New Mexico',
	'New York',
	'North Carolina',
	'North Dakota',
	'Ohio',
	'Oklahoma',
	'Oregon',
	'Pennsylvania',
	'Rhode Island',
	'South Carolina',
	'South Dakota',
	'Tennessee',
	'Texas',
	'Utah',
	'Vermont',
	'Virginia',
	'Washington',
	'West Virginia',
	'Wisconsin',
	'Wyoming',
];

const addressFormValidationSchema = z.object({
	addressLine1: z.string().min(1, 'Address Line 1 is required.'),
	addressLine2: z.string().optional(),
	city: z.string().min(1, 'City is required.'),
	state: z.enum(usStates, { errorMap: () => ({ message: 'Invalid state name.' }) }),
	zip: z
		.string()
		.min(5, 'Zip code must be at least 5 characters long.')
		.regex(/^\d+$/, 'Zip code must contain only numbers.'),
});

export default addressFormValidationSchema;
