import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/';

import PageHeading from '../../../core/components/PageHeading';
import ProfessionalStatementForm from '../../components/ProfessionalStatementForm';

import { useUpdateCareProInfoMutation } from '../../api/mutations/useUpdateCareProInfoMutation';

import { useAuth } from '../../../core/hooks/useAuth';

import { authRoutes } from '../../routes/constants';

import { StyledProfessionalStatementPage } from './styles';

const ProfessionalStatementPage = () => {
	const [serverError, setServerError] = useState('');
	const history = useHistory();

	const { refreshAuthCarePro } = useAuth();
	const updateCareProInfo = useUpdateCareProInfoMutation();

	const professionalStatementHandler = async (values) => {
		try {
			await updateCareProInfo.mutateAsync({
				professional_statement: values.professionalStatement,
			});

			refreshAuthCarePro();
			history.push(authRoutes.onboarding.home);
		} catch (error) {
			console.error('Professional statement update failed', error);
			setServerError(error[0].message);
		}
	};

	return (
		<StyledProfessionalStatementPage>
			<PageHeading>Professional Statement</PageHeading>

			<ProfessionalStatementForm
				initialValues={{
					professionalStatement: '',
				}}
				professionalStatementHandler={professionalStatementHandler}
				serverError={serverError}
			/>
		</StyledProfessionalStatementPage>
	);
};

export default ProfessionalStatementPage;
