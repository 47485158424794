import React from 'react';

import {
	StyledDocumentsListContainer,
	StyledDocumentsListWrapper,
	StyledTitle,
	StyledDocumentList,
	StyledDocument,
	StyledDocumentTitle,
	StyledDocumentDate,
} from './styles';

const DocumentsList = (props) => {
	const { title, documents, rejected } = props;

	return (
		<StyledDocumentsListContainer>
			<StyledDocumentsListWrapper>
				<StyledTitle>{title}</StyledTitle>

				<StyledDocumentList>
					{documents.map((document, index) => (
						<StyledDocument key={index}>
							<StyledDocumentTitle
								href={document.uploadedDocuments[0].document_url}
								target='_blank'
							>
								{document.document_name}
							</StyledDocumentTitle>
							<StyledDocumentDate>
								Uploaded on:{' '}
								{new Date(document.uploaded_date).toLocaleString('en-US', {
									month: 'long',
									day: 'numeric',
									year: 'numeric',
									hour: 'numeric',
									minute: 'numeric',
									hour12: true,
								})}
							</StyledDocumentDate>

							{document.expiration_date && (
								<StyledDocumentDate>
									Expriring on:{' '}
									{new Date(document.expiration_date).toLocaleString('en-US', {
										month: 'long',
										day: 'numeric',
										year: 'numeric',
										hour: 'numeric',
										minute: 'numeric',
										hour12: true,
									})}
								</StyledDocumentDate>
							)}
							{rejected && document.rejection_notes && (
								<StyledDocumentDate>
									{' '}
									- {document.rejection_notes}
								</StyledDocumentDate>
							)}
						</StyledDocument>
					))}
				</StyledDocumentList>
			</StyledDocumentsListWrapper>
		</StyledDocumentsListContainer>
	);
};

export default DocumentsList;
