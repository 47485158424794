import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledCarePlanManagementPage = styled.div`
	display: flex;
	box-sizing: border-box !important ;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 20px;

	@media ${mediaQueries.tablet} {
		padding: 20px 20px;
	}

	@media ${mediaQueries.laptop} {
		padding: 0 40px;
	}

	@media ${mediaQueries.desktop} {
		max-width: 1100px;
	}
`;

export const StyledWrapper = styled.div`
	max-width: 1200px;
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center !important;
	justify-content: center;
`;

export const StyledHeadingContainer = styled.div`
	box-sizing: border-box !important ;

	display: flex;
	text-align: left;
	width: 100%;
	align-items: center;
	justify-content: flex-start;

	@media ${mediaQueries.mobileM} {
		padding: 15px 0;
	}

	@media ${mediaQueries.laptop} {
		padding: 25px 0;
	}
`;

export const StyledDescription = styled.p`
	box-sizing: border-box !important ;

	font-size: 16px;
	line-height: 1.7;
	color: ${colors.primary.primary};
	margin-bottom: 20px;

	@media ${mediaQueries.mobileS} {
		font-size: 14px;
	}

	@media ${mediaQueries.tablet} {
		font-size: 15px;
	}

	@media ${mediaQueries.laptop} {
		font-size: 18px;
	}
`;

export const StyledCarePlanItemsContainer = styled.div`
	box-sizing: border-box !important ;
	display: grid;
	align-items: initial;
	grid-gap: 40px;
	width: 100%;
	grid-template-columns: 1fr;
	box-sizing: border-box;

	@media ${mediaQueries.mobileL} {
		grid-template-columns: 1fr 1fr;
	}

	@media ${mediaQueries.tablet} {
		grid-template-columns: 1fr 1fr;
	}

	@media ${mediaQueries.laptop} {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
`;

export const StyledCardItem = styled.div`
	padding: 10px;
	box-sizing: border-box !important ;

	@media ${mediaQueries.mobileL} {
		padding: 15px;
	}

	@media ${mediaQueries.tablet} {
		padding: 20px;
	}

	@media ${mediaQueries.laptop} {
		padding: 25px;
	}
`;
