import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TransparentButton } from '../../../../../../corev2/Buttons';
import PageHeading from '../../../../core/components/PageHeading';
import PersonalInfoForm from '../../../components/PersonalInfoForm';

import { useUpdateSetTraitsMutation } from '../../../api/mutations/useUpdateSetTraitsMutation';
import { useCarePlan } from '../../../hooks/useCarePlan';
import { useAuth } from '../../../../core/hooks/useAuth';

import { carePlanRoutes } from '../../../routes/constants';

import { StyledPersonalPage } from './styles';

const PersonalPage = () => {
	const history = useHistory();

	const [serverError, setServerError] = useState('');

	const updateSetTraitsMutation = useUpdateSetTraitsMutation();
	const { carePlanState, mutateCarePlanState } = useCarePlan();
	const { authUser } = useAuth();

	const personalInfoFormHandler = async (values) => {
		try {
			await updateSetTraitsMutation.mutateAsync({
				setId: authUser.carePlan.carePlanSet.id,
				traits: [
					{ trait_id: 67, value: values.jobStatement },
					{ trait_id: 68, value: values.weight.toString() },
					{ trait_id: 69, value: values.diagnosis },
					{ trait_id: 70, value: values.emergencyContact1Name },
					{ trait_id: 71, value: values.emergencyContact1Relationship },
					{ trait_id: 72, value: values.emergencyContact1Phone },
					{ trait_id: 73, value: values.emergencyContact2Name },
					{ trait_id: 74, value: values.emergencyContact2Relationship },
					{ trait_id: 75, value: values.emergencyContact2Phone },
				],
			});

			mutateCarePlanState('personal', {
				...carePlanState.personal,
				data: {
					jobStatement: values.jobStatement,
					weight: values.weight,
					diagnosis: values.diagnosis,
					emergencyContact1Name: values.emergencyContact1Name,
					emergencyContact1Relationship: values.emergencyContact1Relationship,
					emergencyContact1Phone: values.emergencyContact1Phone,
					emergencyContact2Name: values.emergencyContact2Name,
					emergencyContact2Relationship: values.emergencyContact2Relationship,
					emergencyContact2Phone: values.emergencyContact2Phone,
				},
				isCompleted: true,
			});
		} catch (error) {
			console.error('Care plan personal info form failed', error);
			setServerError(error[0].message);
		}
	};

	const backButtonHandler = () => {
		history.push(carePlanRoutes.onboarding.info);
	};

	return (
		<StyledPersonalPage>
			<PageHeading>Personal Infomation</PageHeading>

			<PersonalInfoForm
				initialValues={{
					jobStatement: carePlanState.personal.data.jobStatement,
					weight: carePlanState.personal.data.weight,
					diagnosis: carePlanState.personal.data.diagnosis,
					emergencyContact1Name: carePlanState.personal.data.emergencyContact1Name,
					emergencyContact1Relationship:
						carePlanState.personal.data.emergencyContact1Relationship,
					emergencyContact1Phone: carePlanState.personal.data.emergencyContact1Phone,
					emergencyContact2Name: carePlanState.personal.data.emergencyContact2Name,
					emergencyContact2Relationship:
						carePlanState.personal.data.emergencyContact2Relationship,
					emergencyContact2Phone: carePlanState.personal.data.emergencyContact2Phone,
				}}
				personalInfoFormHandler={personalInfoFormHandler}
				serverError={serverError}
			/>

			<TransparentButton size='small' onClick={backButtonHandler}>
				Go back
			</TransparentButton>
		</StyledPersonalPage>
	);
};

export default PersonalPage;
