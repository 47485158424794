import React, { useState } from 'react';

import PageHeading from '../../../../core/components/PageHeading';
import InfoForm from '../../../components/InfoForm';

import { useHistory } from 'react-router-dom';

import { useUpdateSetTraitsMutation } from '../../../api/mutations/useUpdateSetTraitsMutation';
import useUpdateCarePlanInfo from '../../../api/mutations/useUpdateCarePlanInfo';

import { useAuth } from '../../../../core/hooks/useAuth';
import { useCarePlan } from '../../../hooks/useCarePlan';

import { StyledInfoPage } from './styles';

import { TransparentButton } from '../../../../../../corev2/Buttons';
const Infopage = () => {
	const [serverError, setServerError] = useState('');

	const updateCarePlanInfo = useUpdateCarePlanInfo();
	const { carePlanState, mutateCarePlanState } = useCarePlan();
	const { authUser, modifyAuthUser } = useAuth();

	const history = useHistory();

	const infoFormHandler = async (values) => {
		try {
			const updateValues = {
				carePlanId: authUser.carePlan.id,
				carePlanInfo: {
					cr_first_name: values.cr_first_name,
					cr_last_name: values.cr_last_name,
					cr_display_name: values.cr_display_name,
					cr_dob: values.cr_dob,
					cr_gender: values.cr_gender,
				},
			};
			console.log('updateValues', updateValues);

			const data = await updateCarePlanInfo.mutateAsync(updateValues);

			modifyAuthUser('carePlan', data);

			mutateCarePlanState('info', {
				...carePlanState.info,
				data: {
					cr_first_name: values.cr_first_name,
					cr_last_name: values.cr_last_name,
					cr_display_name: values.cr_display_name,
					dob: values.cr_dob,
					gender: values.cr_gender,
				},
				isCompleted: true,
			});
		} catch (error) {
			console.error('Care plan client info form failed', error);
			setServerError(error[0].message);
		}
	};

	const backButtonHandler = () => {
		history.push(carePlanRoutes.onboarding.logistics);
	};
	// handle going to previous page

	console.log('authUser', authUser);
	return (
		<StyledInfoPage>
			<PageHeading>Care Recipient Information</PageHeading>
			<InfoForm
				initialValues={{
					cr_first_name:
						authUser.carePlan.cc_relation_to_cr === 'self'
							? authUser.carePlan.cc_first_name
							: '',
					cr_last_name:
						authUser.carePlan.cc_relation_to_cr === 'self'
							? authUser.carePlan.cc_last_name
							: '',
					cr_display_name: authUser.carePlan.cr_display_name || '',
					cr_dob: authUser.carePlan.cr_dob || '',
					cr_gender: authUser.carePlan.cr_gender || '',
				}}
				infoFormHandler={infoFormHandler}
				serverError={serverError}
			/>
			<TransparentButton size='small' onClick={backButtonHandler}>
				Go Back
			</TransparentButton>
		</StyledInfoPage>
	);
};

export default Infopage;
