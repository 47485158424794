import { z } from 'zod';

const personalInfoFormValidationSchema = z.object({
	//weight: z.string().min(1, 'Weight is required.'),
	diagnosis: z.string().min(1, 'Diagnosis is required.'),
	emergencyContact1Name: z.string().min(1, 'Emergency Contact 1 Name is required.'),
	emergencyContact1Relationship: z
		.string()
		.min(1, 'Emergency Contact 1 Relationship is required.'),
	emergencyContact1Phone: z.string().min(1, 'Emergency Contact 1 Phone is required.'),
});

export default personalInfoFormValidationSchema;
