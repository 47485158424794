import React from 'react';
import { Hearts } from 'react-loader-spinner';
import Colors from 'colors';

import {
	StyledSkillsPage,
	SkillsContainer,
	SkillsFormCon,
	StyledPageHeadingContainer,
	PageHeading,
	LoaderSection,
} from '../styles';
import useFetchCareProSkills from '../api/useFetchCareProSkills';
import useUpdateCareProFormAnswers from '../api/useUpdateCareProFormAnswer';
import { toast } from 'react-toastify';

import { TOAST_SUCCESS_CONFIG, TOAST_ERROR_CONFIG } from 'constants';

import SkillsFormGen from './SkillFormGen';
import Text from 'AppComp/Text';
const SkillsPage = () => {
	const { data, isLoading } = useFetchCareProSkills();

	const updateCareProFormAnswers = useUpdateCareProFormAnswers();
	if (isLoading) {
		return (
			<LoaderSection>
				<div
					style={{
						flex: 1,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
					}}
				>
					<Hearts color={Colors.primary.primary} height={100} width={100} />
				</div>
			</LoaderSection>
		);
	}

	console.log(data);

	if (data === undefined || data === null) {
		return (
			<LoaderSection>
				<Text>Form not found</Text>
			</LoaderSection>
		);
	}

	const handleSubmit = async (values) => {
		console.log('submitting form', values);
		// do whatever you need to move forward

		// you can assume that this can be called after
		try {
			const updateVal = await updateCareProFormAnswers.mutateAsync({
				formId: data.id,
				answers: values,
			});

			// toast
		} catch (err) {
			console.error(err);
		}
	};
	return <SkillsFormGen handleSubmit={handleSubmit} formData={data} />;
};

export default SkillsPage;
