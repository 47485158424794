import moment from 'moment';
import { colors } from '../../utils';

export const eventStyleGenerator = (event, start, end, isSelected) => {
	let style = {};

	// Check if the event start time is in the past
	const isPastEvent = moment(event.start).isBefore(moment());
	const opacity = isPastEvent ? 0.2 : 1;

	switch (event.resource.type) {
		case 'available':
			style = {
				backgroundColor: colors.primary.primary,
				opacity: opacity,
				color: colors.white,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
			break;

		case 'offered':
			style = {
				backgroundColor: '#f5a623', // Orange color for offered shifts
				opacity: opacity,
				color: colors.white,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
			break;

		case 'pending':
			style = {
				backgroundColor: '#4caf50',
				opacity: opacity,
				color: colors.white,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
			break;

		case 'clocked_in':
			style = {
				backgroundColor: '#2196f3', // Blue color for clocked-in shifts
				opacity: opacity,
				color: colors.white,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
			break;

		case 'clocked_out':
			style = {
				backgroundColor: '#9e9e9e', // Gray color for clocked-out shifts
				opacity: opacity,
				color: colors.white,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
			break;

		case 'submitted':
			style = {
				backgroundColor: '#673ab7', // Purple color for submitted shifts
				opacity: opacity,
				color: colors.white,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
			break;

		case 'canceled':
			style = {
				backgroundColor: '#e53935', // Red color for canceled shifts
				opacity: opacity,
				color: colors.white,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
			break;

		case 'paid':
			style = {
				backgroundColor: '#ffd600', // Yellow color for paid shifts
				opacity: opacity,
				color: colors.black,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
			break;

		default:
			style = {
				backgroundColor: colors.primary.primary,
				opacity: opacity,
				color: colors.white,
				borderRadius: '0px',
				border: '0px',
				display: 'block',
			};
	}

	return { style };
};
