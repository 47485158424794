import React, { useState } from 'react';
import Dropzone from 'react-dropzone';

import {
	StyledDocumentsPage,
	StyledDocumentsContainer,
	StyledDocumentsWrapper,
	StyledTitle,
	StyledDocumentRequest,
	StyledDocumentCard,
	StyledDocumentTitle,
	StyledDocumentUploadArea,
	StyledUploadFilesText,
	StyledFileInput,
	StypedExpirationLabel,
	StyledClearFileIcon,
	StyledButtonContainer,
	UploadButton,
} from '../../pages/DocumentsPage/styles';

import {
	StyledDocumentList,
	StyledDocument,
	StyledDocumentTitle as UploadedPreviewTitle,
} from '../DocumentsList/styles';
import { Input } from '../../../../../corev2/Forms';

const UploadTile = ({ uploaded, documentRequirement, handleUploadDocuments }) => {
	const [newImage, setNewImage] = useState(false);

	const [loading, setLoading] = useState(false);

	const [checkFiles, setCheckFiles] = useState([]);

	const [filesToUpload, setFilesToUpload] = useState([]);

	const [expirationDate, setExpirationDate] = useState(null);

	const [newImageUrl, setNewImageUrl] = useState(uploaded ? uploaded.document_url : '');

	const uploadFilesToS3 = async () => {
		try {
			setLoading(true);
			const identifyPoolId = 'us-east-1:66059173-f49e-413c-840a-3be0bd12aa8f';
			const bucketRegion = 'us-east-1';
			const bucketName = 'documents-uploads-2';

			AWS.config.update({
				region: bucketRegion,
				credentials: new AWS.CognitoIdentityCredentials({
					IdentityPoolId: identifyPoolId,
				}),
			});

			const s3 = new AWS.S3({
				apiVersion: '2012-10-17',
				params: { Bucket: bucketName },
			});

			// Update the UI to indicate loading
			// Make sure you have a state setter for loading

			const uploadPromises = filesToUpload.map((file) => {
				console.log('file', file);
				const key = `${Date.now()}-${file.name}`; // This ensures a unique key for each file

				const fileType = file.type;
				return new Promise((resolve, reject) => {
					s3.upload(
						{
							Key: key,
							Body: file,
							ACL: 'public-read',
							ContentType: fileType,
						},
						(err, data) => {
							if (err) {
								reject(err);
							} else {
								resolve(data);
							}
						}
					);
				});
			});

			Promise.all(uploadPromises)
				.then((results) => {
					console.log('All files uploaded:', results);
					// Update the UI to indicate success
					const filesLocations = results.map((result) => result.Location);
					handleUploadCallback(filesLocations);
				})
				.catch((errors) => {
					console.error('Errors during upload:', errors);
					// Update the UI to indicate error
					setLoading(false);
				});
		} catch (err) {
			console.log('err', err);
		}
	};

	const handleUploadCallback = async (filesLocations) => {
		//once the call back is called we either should show an error or run handleSignatureUpdate to pass the info back to the parent and handle removing the signature pad
		try {
			// ensue if there is an expiration date that it is parsed for sql date format
			console.log('filesLocations', filesLocations);
			if (expirationDate) {
				const date = new Date(expirationDate);
				const sqlDate = date.toISOString().slice(0, 19).replace('T', ' ');
				const uploadedThing = await handleUploadDocuments(
					filesLocations,
					documentRequirement.id,
					setLoading,
					sqlDate
				);
				if (uploadedThing) {
					setCheckFiles([]);
					setFilesToUpload([]);
					setExpirationDate(null);
				}
				setLoading(false);
			} else {
				const callBackRes = await handleUploadDocuments(
					filesLocations,
					documentRequirement.id,
					setLoading
				);

				if (callBackRes) {
					setCheckFiles([]);
					setFilesToUpload([]);
					setExpirationDate(null);
				}

				setLoading(false);
			}
		} catch (err) {
			setLoading(false);
			console.log('handleUploadCallback - err', err);
		}
	};

	const handleDroppedFile = (acceptedFiles) => {
		console.log('acceptedFiles', acceptedFiles);
		var imageTypes = [
			'image/heic',
			'image/webp',
			'image/png',
			'image/bmp',
			'image/jpg',
			'image/jpeg',
			'application/pdf',
		];

		// ensure all the files are within the imageTypes array

		const fileCheck = acceptedFiles.filter((file) => {
			if (!imageTypes.includes(file.type)) {
				return true;
			}

			return false;
		});

		if (fileCheck.length > 0) {
			//toast.error('File type not supported', TOAST_ERROR_CONFIG);
			setLoading(false);
			return;
		}

		setCheckFiles(
			acceptedFiles.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				})
			)
		);

		setFilesToUpload(acceptedFiles);
	};

	const renderIconStatus = () => {
		const status = '';

		if (status === 'pending') {
			return <Icon icon={faHourglass} size='xl' color={Colors.yellow} />;
		} else if (status === 'approved') {
			return <Icon icon={faCheck} size='xl' color={Colors.secondary.green} />;
		} else if (status === 'rejected') {
			return <Icon icon={faTimesCircle} size='xl' color={Colors.red} />;
		} else if (status === 'not_uploaded') {
			return <Icon icon={faUpload} size='xl' color={Colors.heartRed} />;
		}
	};

	console.log('documentRequirement', documentRequirement);
	console.log('filesToUpload', filesToUpload);
	return (
		<StyledDocumentCard>
			<StyledDocumentTitle>{documentRequirement.name}</StyledDocumentTitle>
			{filesToUpload.length > 0 && (
				<StyledClearFileIcon
					onClick={() => {
						setCheckFiles([]);
						setFilesToUpload([]);
					}}
				>
					✖
				</StyledClearFileIcon>
			)}

			<Dropzone onDrop={(acceptedFiles) => handleDroppedFile(acceptedFiles)}>
				{({ getRootProps, getInputProps }) => (
					<StyledDocumentUploadArea {...getRootProps()}>
						<input {...getInputProps()} />
						<StyledUploadFilesText>Upload File(s)</StyledUploadFilesText>
					</StyledDocumentUploadArea>
				)}
			</Dropzone>
			{checkFiles.length > 0 && (
				<StyledDocumentList
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '5px',
					}}
				>
					{checkFiles.map((file, index) => (
						<UploadedPreviewTitle
							href={file.preview}
							target='_blank'
							rel='noopener noreferrer'
						>
							{file.name}
						</UploadedPreviewTitle>
					))}
				</StyledDocumentList>
			)}
			{documentRequirement.requires_expiration && (
				<>
					<StypedExpirationLabel
						htmlFor={`expiration-date-agency-${documentRequirement.id}`}
					>
						Expiration Date
					</StypedExpirationLabel>
					<Input
						type='date'
						id={`expiration-date-agency-${documentRequirement.id}`}
						onChange={(e) =>
							expirationDateChangeHandler(e, documentRequirement.id, 'agency')
						}
					/>
				</>
			)}
			<div
				style={{
					margin: '10px 0',
				}}
			/>
			{filesToUpload.length > 0 && (
				<UploadButton size='small' onClick={() => uploadFilesToS3()}>
					{loading ? 'Uploading...' : 'Upload Documents'}
				</UploadButton>
			)}
		</StyledDocumentCard>
	);
};

export default UploadTile;
