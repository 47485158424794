import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import useWindowSize from 'scripts/useWindowSize';

import colors from 'colors';

import CareProConversations from 'AppComp/provider/CareProChat/CareProConversations';

import ChatView from 'AppComp/provider/CareProChat/ChatView';

import { ParentContainer } from '@appComp/provider/ProviderHomeDashboard';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export const StyledButton = styled.button`
	background: inherit;
	border: none;
	margin-right: 8px;
`;

import Icon from 'AppComp/Icon';

const ClientChatHandler = (props) => {
	const { conversations, sendMessageHandler, push } = props;

	const [selectedConversation, setSelectedConversation] = useState(null);

	const history = useHistory();
	const { width } = useWindowSize();
	const { chatId } = useParams();
	const location = useLocation();

	useEffect(() => {
		if (selectedConversation) {
			// find the conversation in the list of conversations
			const conversation = conversations.find((d) => d.id === selectedConversation.id);
			if (conversation) {
				setSelectedConversation(conversation);
			}
		}
	}, [conversations]);

	useEffect(() => {
		if (selectedConversation) {
			push(selectedConversation.id);
		}
	}, [selectedConversation]);

	useEffect(() => {
		const conversationIdCheck = location.pathname.split('/');

		if (conversationIdCheck.length >= 2) {
			const convoCheckId = parseInt(conversationIdCheck[3]);
			const conversation = conversations.find((d) => d.id === convoCheckId);
			if (conversation) {
				setSelectedConversation(conversation);
			}
		}
	}, [location, conversations]);

	const backButtonHandler = () => {
		history.push('/care-pro/chat');
	};

	const handleSendMessage = async (message) => {
		console.log('selectedConversation', selectedConversation);

		if (selectedConversation.user_type === 'carePro') {
			sendMessageHandler(selectedConversation.id, message, selectedConversation.provider.id);
		} else {
			sendMessageHandler(selectedConversation.id, message);
		}
	};

	const historyBackButton = () => {
		history.goBack();
	};

	return (
		<ParentContainer
			style={{
				flex: 1,
				display: 'flex',
				flexDirection: 'row',
				backgroundColor: colors.theme.primary_background,
				padding: '0 12px',
			}}
		>
			{width < 1024 && chatId && (
				<ChatView
					selectedConversation={selectedConversation}
					handleSendMessage={handleSendMessage}
					loggedInUser={'patient'}
					backButtonHandler={backButtonHandler}
				/>
			)}

			{width < 1024 && !chatId && (
				<CareProConversations
					setSelectedConversation={setSelectedConversation}
					selectedConversation={selectedConversation}
					conversations={conversations}
					loggedInUser={'patient'}
				/>
			)}

			{!(width < 1024) && (
				<>
					{!chatId ? (
						<CareProConversations
							setSelectedConversation={setSelectedConversation}
							selectedConversation={selectedConversation}
							conversations={conversations}
							loggedInUser={'patient'}
						/>
					) : (
						<StyledButton
							style={{
								alignSelf: 'flex-start',
								padding: '50px',
							}}
							onClick={() => historyBackButton()}
						>
							<Icon color={colors.primary.primary} icon={faArrowLeft} size='2xl' />
						</StyledButton>
					)}

					<ChatView
						selectedConversation={selectedConversation}
						handleSendMessage={handleSendMessage}
						loggedInUser={'patient'}
						backButtonHandler={backButtonHandler}
					/>
				</>
			)}
		</ParentContainer>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		push: (chatId) => dispatch(push(`/care-pro/chat/${chatId}`)),
	};
};

export default connect(null, mapDispatchToProps)(ClientChatHandler);
