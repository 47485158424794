import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { FormContainer, Input, Label, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import FormWrapper from '../../components/FormWrapper';
import InputContainer from '../../components/InputContainer';

import personalInfoFormValidationSchema from './validation';

const PersonalInfoForm = (props) => {
	const { serverError, initialValues, personalInfoFormHandler } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => personalInfoFormHandler(values),
		validationSchema: toFormikValidationSchema(personalInfoFormValidationSchema),
		enableReinitialize: true,
	});

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<Label> Weight</Label>

					<Input
						name='weight'
						type='number'
						onChange={formik.handleChange}
						placeholder='Weight'
						value={formik.values.weight}
					/>

					{formik.errors.weight && <ErrorMessage> {formik.errors.weight} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Label> Diagnosis</Label>

					<Input
						name='diagnosis'
						type='text'
						onChange={formik.handleChange}
						placeholder='Diagnosis'
						value={formik.values.diagnosis}
					/>

					{formik.errors.diagnosis && (
						<ErrorMessage> {formik.errors.diagnosis} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> Emergency Contact 1 Name</Label>

					<Input
						name='emergencyContact1Name'
						type='text'
						onChange={formik.handleChange}
						placeholder='Emergency Contact 1 Name'
						value={formik.values.emergencyContact1Name}
					/>

					{formik.errors.emergencyContact1Name && (
						<ErrorMessage> {formik.errors.emergencyContact1Name} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> Emergency Contact 1 Relationship</Label>

					<Input
						name='emergencyContact1Relationship'
						type='text'
						onChange={formik.handleChange}
						placeholder='Emergency Contact 1 Relationship'
						value={formik.values.emergencyContact1Relationship}
					/>

					{formik.errors.emergencyContact1Relationship && (
						<ErrorMessage> {formik.errors.emergencyContact1Relationship} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> Emergency Contact 1 Phone</Label>

					<Input
						name='emergencyContact1Phone'
						type='tel'
						onChange={formik.handleChange}
						placeholder='Emergency Contact 1 Phone'
						value={formik.values.emergencyContact1Phone}
					/>

					{formik.errors.emergencyContact1Phone && (
						<ErrorMessage> {formik.errors.emergencyContact1Phone} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> Emergency Contact 2 Name</Label>

					<Input
						name='emergencyContact2Name'
						type='text'
						onChange={formik.handleChange}
						placeholder='Emergency Contact 2 Name'
						value={formik.values.emergencyContact2Name}
					/>

					{formik.errors.emergencyContact2Name && (
						<ErrorMessage> {formik.errors.emergencyContact2Name} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> Emergency Contact 2 Relationship</Label>

					<Input
						name='emergencyContact2Relationship'
						type='text'
						onChange={formik.handleChange}
						placeholder='Emergency Contact 2 Relationship'
						value={formik.values.emergencyContact2Relationship}
					/>

					{formik.errors.emergencyContact2Relationship && (
						<ErrorMessage> {formik.errors.emergencyContact2Relationship} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> Emergency Contact 2 Phone</Label>

					<Input
						name='emergencyContact2Phone'
						type='tel'
						onChange={formik.handleChange}
						placeholder='Emergency Contact 2 Phone'
						value={formik.values.emergencyContact2Phone}
					/>

					{formik.errors.emergencyContact2Phone && (
						<ErrorMessage> {formik.errors.emergencyContact2Phone} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<PrimaryButton size='small' type='submit'>
						Save
					</PrimaryButton>

					{serverError && <ErrorMessage> {serverError} </ErrorMessage>}
				</InputContainer>
			</FormWrapper>
		</FormContainer>
	);
};

export default PersonalInfoForm;
